<template>
  <list-template
      :loading="loading"
      :search-config="searchConfig"
      @onSearch="onSearch"
      :isDownload="true"
      @onExportData="onExport(false,true)"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :current-page="page"
      @onChangePage="val=>{$store.commit('setPage',val);getData()}"
  ></list-template>
</template>

<script>
import {mapState} from "vuex";
import {getSchoolInfo, getSubject} from "@/api/common";

export default {
  _config: {"route": {"path": "list", "meta": {"title": "统计"}}},
  computed: {
    ...mapState(['page'])
  },
  data(){
    return {
      loading:true,
      total:0,
      search:{},
      searchConfig:[
        {prop:'task_config_id',placeholder:'筛选任务名称',tag:"select",options:[],label:"task_name",value:"task_config_id"},
        {prop:'school_id',placeholder:'筛选校区',tag:"select",options:[],label:"name",value:"id"},
        {prop:'grade_id',placeholder:'筛选年级',tag:"select",options:[],label:"name",value:"id"},
        {prop:'qualified',placeholder:'筛选是否合格',tag:"select",options:[{
          label:"合格",
          value:1
        },{
          label:"不合格",
          value:0
          }]},
        {prop:'subject_id',placeholder:'筛选学科',tag:"cascader",options:[],label:"subject_name",value:"id"},
        {prop:'teacher_name',placeholder:'搜索教师姓名'},
      ],
      tableData:[],
      tableConfig:[
        {prop:"task_name",label:"任务周期名称"},
        {prop:"teacher_name",label:"教师姓名"},
        {prop:"subject_name",label:"学科"},
        {prop:"completed",label:"已辅导学生人数"},
        {prop:"completion_min",label:"最低辅导人数"},
        {prop:"completion_rate",label:"目前完成率"},
        {prop:"qualified",label:"是否合格"},
        {prop:"end_at",label:"任务周期结束时间"},
        {prop:"grade_name",label:"年级"},
        {prop:"school_name",label:"学校"},
      ]
    }
  },
  mounted() {
    this.init();
    this.getData();
  },
  methods:{
    /**
     * 通过关键键和关键值,从searchConfig中查找对应的数据对象 , 然后更新其options
     *  更新搜索配置的option
     * @param key 关键key
     * @param value 关键值
     * @param options 需要注入的option
     */
    updateOptions(key,value,options = null){
      let searchConfig = this.searchConfig;
      for (let i in searchConfig){
        let item = searchConfig[i];
        if (item[key] === value){
          if (options)
            this.$set(this.searchConfig[i],"options",options);
          return item.options;
        }
      }
    },
    init(){
      // 获取任务名称
      this.$_axios2.get("api/coach-statistics/coach-conf",{logic:1}).then(res=>{
        this.updateOptions("placeholder","筛选任务名称",res.data)
      })
      // 获取校区和年级
      getSchoolInfo(2).then(res=>{
        let {school,action_grade} = res.data;
        this.updateOptions("placeholder","筛选校区",school)
        this.updateOptions("placeholder","筛选年级",action_grade)
      })
      // 获取科目
      getSubject(3).then(res=>{
        this.updateOptions("placeholder","筛选学科",res.data)
      })
    },
    /**
     * 获取数据
     * @param isInit 是否初始化
     * @param isExport 是否导出
     */
    getData(isInit = false,isExport=false){
      let page = this.page;
      if (isInit){
        this.$store.commit("setPage",1);
        page = 1;
      }
      let params = {...JSON.parse(JSON.stringify(this.search)), page};
      params.subject_id = params.subject_id ? params.subject_id.pop() : undefined;
      this.loading = true;
      this.$_axios2.get("api/coach-statistics/list",{logic:1,params}).then(res=>{
          let {list,page:p} = res.data;
          this.tableData = list;
          this.total = p.total;
      }).finally(()=>this.loading = false)
    },
    onExport(){
      this.$_axios2.get("api/coach-statistics/list-export",{params:this.search}).then(res=>{
        this.$tools.download("任务完成情况统计",res.data);
      })
    },
    onSearch(val){
      this.$store.commit('setPage', 1)
      this.search = val;
      this.getData();
    }
  }
}
</script>

<style scoped>

</style>
